























import { defineComponent, toRef } from '@vue/composition-api';
export default defineComponent({
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		handlerCancel: {
			type: Function,
		},
		loadingBtn: {
			type: Boolean,
			default: false,
		},
		handlerConfirm: {
			type: Function,
		},

		errMsg: {
			type: String,
			default: '',
		},
		confirmContent: {
			type: String,
			default: '',
		},
		confirmBtnCancel: {
			type: String,
			default: '',
		},
		confirmBtnYes: {
			type: String,
			default: '',
		},
	},
});
