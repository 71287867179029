<template>
  <div class="container-drafts">
    <div class="row m-0">
      <div class="col-xxl-12 px-0 bd-bottom d-flex align-items-center justify-content-between">
        <div class="display-flex flex-column px-5 py-2">
          <p class="font-weight-bold mb-0 font-size-14 text-blur-color">
            Mã số {{ currentData.code || currentData.id }}
            <v-tooltip v-if="!currentData.active_notification" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    class="pointer ml-2 disable-icon-pr bell-icon"
                    :class="loadingBtnUnapproval ? 'disable-icon' : ''"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleNoti(true)"
                >
                  mdi-bell-off
                </v-icon>
              </template>
              <span>Thông báo về đề xuất này đang được tắt</span>
            </v-tooltip>
            <v-tooltip v-if="currentData.active_notification" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    class="pointer ml-2 bell-icon"
                    :class="loadingBtnUnapproval ? 'disable-icon' : ''"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleNoti(false)"
                >
                  mdi-bell
                </v-icon>
              </template>
              <span>Thông báo về đề xuất này đang được bật</span>
            </v-tooltip>
          </p>
          <div class="d-flex align-items-center propose-tab py-2">
            <div class="trash" v-if="currentData.status && currentData.status.id === 1">Nháp</div>
            <div class="sent" v-if="currentData.status && currentData.status.id === 2">Đã gửi</div>
            <div class="approved" v-if="currentData.status && currentData.status.id === 3">Đã phê duyệt</div>
            <div class="no-approve" v-if="currentData.status && currentData.status.id === 4">Không phê duyệt</div>
            <div class="cancel" v-if="currentData.status && currentData.status.id === 5">Hủy</div>
            <!-- <div
              class="each-status"
              :class="
                currentData.status && currentData.status.id === 1
                  ? 'status--active'
                  : ''
              "
            >
              Nháp
              <v-icon> mdi-chevron-right </v-icon>
            </div>
            <div
              class="each-status"
              :class="
                currentData.status && currentData.status.id === 2
                  ? 'status--active'
                  : ''
              "
            >
              Đã gửi
              <v-icon> mdi-chevron-right </v-icon>
            </div>
            <div
              class="each-status"
              :class="
                currentData.status && currentData.status.id === 3
                  ? 'status--active'
                  : ''
              "
            >
              Đã phê duyệt
              <v-icon> mdi-chevron-right </v-icon>
            </div>
            <div
              class="each-status"
              :class="
                currentData.status && currentData.status.id === 4
                  ? 'status--active'
                  : ''
              "
            >
              Không phê duyệt
              <v-icon> mdi-chevron-right </v-icon>
            </div>
            <div
              class="each-status"
              :class="
                currentData.status && currentData.status.id === 5
                  ? 'status--active'
                  : ''
              "
            >
              Hủy
            </div> -->
          </div>
        </div>
        <div>
          <v-btn
              v-if="currentDataComputed && currentDataComputed.id"
              color="#fff"
              class="btn-approve text-uppercase font-size-18 text-primary-color px-3 mr-4 font-weight-bold"
              @click="handleCopy(currentDataComputed)"
          >
            {{ 'Sao chép' }}
          </v-btn
          >
          <v-btn
              v-if="
							currentDataComputed.status && currentDataComputed.status.id === 2 && currentDataComputed.is_approvable
						"
              color="#fff"
              class="btn-approve pointer text-uppercase font-size-18 text-primary-color px-3 mr-4 font-weight-bold"
              @click="openConfirmApproval"
              :loading="loadingBtn || loadingBtnDecline || loadingBtnUnapproval"
              :disabled="loadingBtn"
          >
            {{ 'Phê Duyệt' }}
          </v-btn
          >
          <v-btn
              v-if="currentDataComputed.can_be_unapproved"
              color="#fff"
              class="btn-approve pointer text-uppercase font-size-18 text-primary-color px-3 mr-4 font-weight-bold"
              :loading="loadingBtn || loadingBtnDecline || loadingBtnUnapproval"
              :disabled="loadingBtnUnapproval"
              @click="onUnapproval"
          >
            {{ 'Thu hồi' }}
          </v-btn
          >

          <v-btn
              v-if="
							currentDataComputed.status && currentDataComputed.status.id === 2 && currentDataComputed.is_approvable
						"
              color="#fff"
              class="btn-decline pointer text-uppercase font-size-18 text-primary-color px-3 font-weight-bold"
              @click="isVisibleDecline = true"
              :disabled="loadingBtn || loadingBtnDecline || loadingBtnUnapproval"
              :loading="loadingBtnDecline"
          >
            {{ 'Không Phê Duyệt' }}
          </v-btn
          >
          <a :href="baseUrl+'/api/approval-requests/'+ currentData.id +'/print'" target="_blank"
             v-if="currentDataComputed && currentDataComputed.id"
             class="btn-approve text-uppercase font-size-14 text-primary-color px-3 mr-4 font-weight-bold"
          >
            <img src="@/assets/images/print-icon-blue.svg" alt=""
            /></a>
          <!-- <v-btn
            v-if="
              currentDataComputed.status && currentDataComputed.status.id === 4
            "
            color="#fff"
            class="
              btn-decline
              pointer
              text-uppercase
              font-size-18
              text-primary-color
              px-3
              font-weight-bold
            "
            :disabled="loadingBtn"
          >
            {{ "Đã từ chối" }}</v-btn
          > -->
        </div>
      </div>
      <div class="drafts-left bg-white" :class="{'col-xxl-7': show_extra_table}" style="position: relative">
        <div style="position: absolute; top: 5px; right: 5px">
          <button class="v-icon notranslate v-icon--link mdi mdi-menu theme--dark primary--text"
                  @click="show_extra_table = !show_extra_table"></button>
        </div>
        <div class="drafts-top px-5 bd-bottom">
          <p class="field-caption font-weight-bold">File đính kèm</p>
          <div v-for="(file, idx) in currentDataComputed.attachments" :key="idx" class="display-flex align-center">
            <a class="display-flex align-center pointer" :href="file.url" target="_blank">
              <img
                  v-if="pdfType.includes(String(file.extension).toLowerCase())"
                  src="@/assets/images/icon-pdf.svg"
                  class="mr-2"
                  height="20"
                  alt=""
              />
              <img
                  v-else-if="docType.includes(String(file.extension).toLowerCase())"
                  src="@/assets/images/doc-icon.png"
                  class="mr-2"
                  height="20"
                  alt=""
              />
              <img
                  v-else-if="excelPath.includes(String(file.extension).toLowerCase())"
                  src="@/assets/images/xls-icon.png"
                  class="mr-2"
                  height="20"
                  alt=""
              />
              <img v-else src="@/assets/images/allFiles.svg" class="mr-2" height="20" alt=""/>
              <span class="text-primary-color">{{ file.original_name }}</span>
            </a>
            <!-- <v-icon
              v-if="currentDataComputed.status && currentDataComputed.status.id === 1"
              color="#ce4b4b"
              class="font-size-16 ml-4"
              @click="removeFileUpload(file.id)"
            >
              mdi-close-circle
            </v-icon> -->
          </div>
          <p
              v-if="!currentDataComputed || !currentDataComputed.attachments || !currentDataComputed.attachments.length"
              class="mb-0"
          >
            Không có
          </p>
        </div>

        <div v-if="currentData.status && currentData.status.id !== 1" class="drafts-top px-5 bd-bottom">
          <p class="font-weight-bold mb-0">Biểu mẫu trình ký</p>
          <!-- <p class="font-weight-bold mb-0">Tài liệu cần ký</p> -->
          <div>
            <a
                v-for="ibm in currentDataComputed.templates"
                :key="ibm.id"
                class="text-primary-color mb-1 pointer d-flex align-items-center justify-content-between"
                :href="baseUrl+'/api/approval-requests/'+ currentData.id +'/sign-documents/'+ ibm.id +'/print'" target="_blank"
            >
							<span class="text-decoration-underline d-flex align-items-center">
								<img src="@/assets/images/pdf-icon-svg.svg" height="18px" alt="" class="pr-2"/>
								{{ ibm.name }}</span
              >
              <!--              <v-tooltip bottom>-->
              <!--                <template v-slot:activator="{ on, attrs }">-->
              <!--                  <v-icon color="primary" dark v-bind="attrs" v-on="on" @click.stop="onPrint(ibm)">-->
              <!--                    mdi-printer-outline-->
              <!--                  </v-icon>-->
              <!--                </template>-->
              <!--                <span>In tài liệu</span>-->
              <!--              </v-tooltip>-->
            </a>
            <p v-if="!currentDataComputed.templates || !currentDataComputed.templates.length" class="mb-0">Không có</p>
          </div>
        </div>
        <div class="drafts-bottom px-5">
          <table class="w-100">
            <tr>
              <th width="60%" class="text-left">
                <span class="field-caption font-weight-bold">Danh mục Phê duyệt</span>
              </th>
              <th width="40%" class="text-left">
                <span class="field-caption font-weight-bold">Mã số</span>
              </th>
            </tr>
            <tr>
              <td>
								<span class="text-primary-color font-weight-bold font-size-16 text-uppercase">{{
                    currentDataComputed.category ? currentDataComputed.category.name : '-'
                  }}</span>
              </td>
              <td>
                <input
                    class="style-input input-f w-100 bg-disable"
                    type="text"
                    :value="currentDataComputed.code || currentDataComputed.id"
                    readonly
                />
              </td>
            </tr>
            <tr>
              <td class="text-left">
                <span class="field-caption font-weight-bold">Người đề xuất</span>
              </td>
              <td class="text-left">
                <span class="field-caption font-weight-bold">Ngày</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="cpn-list-item display-flex align-center">
                  <v-avatar size="30">
                    <img
                        :src="currentDataComputed.creator ? currentDataComputed.creator.avatar : '@/assets/logo.png'"
                        alt="Avatar"
                        class="avatar-create-user"
                    />
                  </v-avatar>
                  <div class="pl-3">
                    <p class="font-weight-bold font-size-14 mb-0">
                      {{ currentDataComputed.creator ? currentDataComputed.creator.full_name : '-' }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <input v-model="currentDataComputed.created_at" class="style-input w-100" type="text"/>
              </td>
            </tr>
          </table>
          <div class="mt-4">
            <p class="mb-1 font-weight-bold">Nội dung</p>
            <input class="style-input w-100 dsb-btn" type="text" readonly :value="currentDataComputed.name"/>
          </div>
          <div class="mt-3">
            <!-- <p class="mb-1 font-weight-bold">Các trường thông tin</p> -->
            <div class="group-field">
              <div v-for="field in currentDataComputed.fields" :key="field.id" class="each-field-in-gr--detail">
                <p
                    v-if="
										(field.values && field.type.id !== 11) || (field.values && field.values.id && field.type.id === 11)
									"
                    class="field-caption font-weight-bold"
                >
                  {{ field.caption }}
                </p>
                <!-- TEXT NGẮN -->
                <template v-if="field.type && field.type.id === 1">
                  <!-- <input type="text" readonly v-model="field.values" /> -->
                  <p class="field-values" v-html="field.values"></p>
                </template>
                <!-- TEXT DÀI -->
                <template v-if="field.type && field.type.id === 2">
                  <!-- <input type="text" readonly v-model="field.values" /> -->
                  <pre class="field-values" v-html="field.values || ''"></pre>
                </template>
                <!-- TRẮC NGHIỆM 1 -->
                <template v-if="field.type && field.type.id === 3">
                  <div class="">
                    <!-- <v-radio-group v-model="field.values">
                      <v-radio
                        readonly
                        v-for="vl in field.dynamic_attributes.choices"
                        :key="vl"
                        :label="vl"
                        :value="vl"
                      ></v-radio>
                    </v-radio-group> -->
                    <p class="field-values" v-html="field.values"></p>
                  </div>
                </template>
                <!-- TRẮC NGHIỆM NHIỀU -->
                <template v-if="field.type && field.type.id === 4">
                  <div v-if="field.values" class="">
                    <p
                        v-for="vl in field.dynamic_attributes.choices"
                        :key="`single-${vl}`"
                        class="option-feild d-flex align-items-center multi-checkbox"
                    >
                      <!-- <v-icon class="mr-2 font-size-20 pointer">mdi-square-outline</v-icon>
                      <input
                        type="text"
                        :placeholder="`Tùy chọn ${idx + 1}`"
                        :value="vl"
                        readonly
                        name=""
                        class="d-block multi-opt"
                      /> -->
                      <v-checkbox v-model="field.values" :key="vl" :label="vl" :value="vl" readonly></v-checkbox>
                    </p>
                  </div>
                </template>
                <!-- NGÀY -->
                <template v-if="field.type && field.type.id === 5">
                  <p class="field-values" v-html="field.values || '---'"></p>
                </template>
                <!-- GIỜ -->
                <template v-if="field.type && field.type.id === 6">
                  <div class="field-picker-date">
                    <p class="field-values" v-html="field.values || '---'"></p>
                  </div>
                </template>
                <!-- NGÀY VÀ GIỜ -->
                <template v-if="field.type && field.type.id === 7">
                  <p class="field-values" v-html="field.values || '---'"></p>
                </template>
                <!-- NGÀY ĐẾN NGÀY -->
                <template v-if="field.type && field.type.id === 8">
                  <div class="field-picker-date d-flex align-items-center mb-2">
                    <p class="mb-0 pr-3 date-time-label font-size-14 wf-60">Từ:</p>

                    <p class="field-values mb-0" v-html="field.values ? field.values[0] : ''"></p>
                  </div>
                  <div class="field-picker-date">
                    <div class="field-picker-date d-flex align-items-center">
                      <p class="mb-0 pr-3 date-time-label font-size-14 wf-60">Đến:</p>
                      <p class="field-values mb-0" v-html="field.values ? field.values[1] : ''"></p>
                    </div>
                  </div>
                </template>
                <!-- GIỜ ĐẾN GIỜ -->
                <template v-if="field.type && field.type.id === 9">
                  <div class="field-picker-date">
                    <div class="d-flex align-items-center">
                      <p class="mb-0 pr-3 date-time-label wf-60">Từ:</p>
                      <div class="time-picker-m">
                        <input type="text" class="font-size-14" :value="field.values ? field.values[0] : ''" readonly/>
                      </div>
                    </div>
                  </div>
                  <div class="field-picker-date">
                    <div class="d-flex align-items-center">
                      <p class="mb-0 pr-3 date-time-label wf-60">Đến:</p>
                      <div class="time-picker-m">
                        <input type="text" class="font-size-14" :value="field.values ? field.values[1] : ''" readonly/>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- BẢNG  -->
                <template v-if="field.type && field.type.id === 10">
                  <table class="table-field mb-2">
                    <thead>
                    <tr>
                      <th v-for="(col, idx) in field.dynamic_attributes.columns" :key="col + idx">
                        <pre class="mb-0" v-html="col || ''"></pre>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(col, idx) in field.values" :key="col + idx">
                      <td v-for="(row, idxe) in col" :key="row + idxe">
                        <pre class="mb-0 px-2" v-html="row || ''"></pre>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div></div>
                </template>
                <template v-if="field.type && field.type.id === 11 && field.values && field.values.id">
                  <p
                      class="field-values"
                      v-html="
											field.values
												? `${field.values.full_name} - ${field.values.asgl_id} - ${field.values.department} - ${field.values.position}`
												: ``
										"
                  ></p>
                </template>
                <!-- BẢNG  -->
                <template v-if="field.type && field.type.id === 12">
                  <table class="table-field mb-2">
                    <thead>
                    <tr>
                      <th v-for="(col, idx) in field.dynamic_attributes.columns" :key="col + idx">
                        <input type="text" :value="col.name" readonly class="input-trt"/>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(col, idx) in field.values" :key="col + idx">
                      <td v-for="(row, idxe) in col" :key="row + idxe">
													<pre
                              v-if="field.dynamic_attributes.columns[idxe].type !== 'number'"
                              class="mb-0 px-2"
                              v-html="row || ''"
                          ></pre>
                        <pre
                            v-if="field.dynamic_attributes.columns[idxe].type === 'number'"
                            class="mb-0 px-2 text-center"
                            v-html="currencyStr(row) || ''"
                        ></pre>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div></div>
                </template>
                <!-- FT -->
                <!-- <div
         class="display-flex align-items-center req-gr-wr justify-content-end"
        >
         <span for="" class="mr-2 font-size-14 d-block mb-0">Bắt buộc</span>
         <v-switch
          color="primary"
          v-model="field.required"
          readonly
          input-value="true"
          class="d-inline-block mt-0 mb-0 pt-0 ctm-switch"
         ></v-switch>
        </div> -->
              </div>
            </div>
          </div>

          <p class="font-weight-bold mt-4 field-caption">Người phê duyệt</p>
          <v-data-table
              :headers="headers"
              :items="currentDataComputed.approver_titles"
              :items-per-page="1000"
              class="box-shadow-unset table-approver"
              hide-default-footer
          >
            <template v-slot:item.user.full_name="{ item }">
              <span class="text-no-wrap">{{ item.user.full_name }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span class="text-no-wrap">{{ getStatusApproval(item.status) }}</span>
            </template>
            // eslint-disable-next-line vue/no-unused-vars
            <template v-slot:item.action="{ item }">
							<span
                  v-if="item.user && auth.user && item.user.id === auth.user.user.id && item.status === 0"
                  class="text-no-wrap pointer"
                  style="color: #006a9b; font-weight: 500"
                  @click="handleSwitchUser(item)"
              >Chuyển việc/Ủy quyền</span
              >
              <!-- <span>{{ item }}</span> -->
            </template>
          </v-data-table>
          <!-- <v-icon class="text-primary-color pointer"> mdi-plus-circle </v-icon> -->
        </div>
      </div>
      <div v-if="show_extra_table" class="drafts-right col-xxl-5 px-0">
        <DraftTabApproval :key="refTabApproval" :current-data="currentData" :get-current-data="getCurrentData"/>
      </div>
    </div>
    <ConfirmReuse
        :is-visible="isVisibleConfirmApproval"
        :confirm-content="confirmContent"
        :confirm-btn-yes="'Xác nhận'"
        :err-msg="confirmErrMsg"
        :loading-btn="loadingBtn"
        :handler-cancel="handlerCancelConfirm"
        :handler-confirm="handleApprove"
    />
    <DialogApproval
        :is-visible="isVisible"
        :left-doc="leftDoc"
        :click-on-doc="clickOnDoc"
        @handlerCancel="handlerDialogCancel"
        @handlerSubmit="handlerDialogSubmit"
    />
    <DialogDecline
        :current-id="currentData.id"
        :is-visible="isVisibleDecline"
        :loading-btn="loadingBtnDecline"
        @handlerCancel="handlerDialogDeclineCancel"
        @handlerSubmit="handleDecline"
    />
    <DialogShowTemplate
        :is-visible="isVisibleTemplate"
        :inner-content="innerContentTemplate"
        :handler-sign="handlerSign"
        :loading-btn="loadingBtnSign"
        @handlerCancel="handlerDialogTemplateCancel"
        @handlerSubmit="handlerDialogSubmit"
    />
    <DialogAddMemberToCate
        :visible-dialog="isVisibleAddMember"
        :on-close="handleCloseAddMember"
        :on-submit="changeApprovalMember"
        :default-member="listApprovalMember"
        :is-multiple="false"
        :loading-btn-submit="loadingBtnChangeApproval"
        :btn-text="'Đồng ý'"
        :title="'Chọn người chuyển việc/ủy quyền'"
    />
    <template>
      <div class="text-center">
        <v-dialog v-model="dialogPrint" width="220mm" persistent class="w-fit">
          <v-card>
            <div class="print-content w-fit mx-auto" v-html="currentDataComputed.print"></div>

            <v-card-actions class="bottom-dialog-btn">
              <v-btn text class="btn-on-cancel white--text" @click="closePrintDialog"> Hủy</v-btn>
              <v-btn text class="btn-on-yes-blue white--text" @click="onPrintPropose(currentDataComputed)"> In</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import {ref, defineComponent} from '@vue/composition-api';
import {useState} from 'vuex-composition-helpers/dist';
import api from '@/services';
import ConfirmReuse from '@/components/popup/ConfirmReuse.vue';
import DialogAddMemberToCate from '@/components/Form/DialogAddMemberToCate.vue';
import {DraftTab, UploadFile, DialogPropose} from '@/components/Layout/page-view/propose';
import {
  DialogApproval,
  DraftTabApproval,
  DialogShowTemplate,
  DialogDecline,
} from '@/components/Layout/page-view/approval';
import {mapState} from 'vuex';
import {tStr} from 'validation_t/src';

export default defineComponent({
  components: {
    DraftTab,
    DraftTabApproval,
    DialogAddMemberToCate,
    UploadFile,
    DialogPropose,
    DialogApproval,
    ConfirmReuse,
    DialogShowTemplate,
    DialogDecline,
    'ckeditor-nuxt': () => {
      return import('@blowstack/ckeditor-nuxt');
    },
  },
  props: {
    currentData: {
      type: Object,
    },
    listTemplate: {
      type: Object,
    },
    getCurrentData: {
      type: Function,
    },
  },
  setup: (props, ctx) => {
    const headers = [
      {
        text: 'Họ tên',
        align: 'start',
        value: 'user.full_name',
        sortable: false,
      },
      {
        text: 'Chức danh',
        align: 'start',
        value: 'title',
        sortable: false,
      },
      {
        text: 'Trạng thái',
        align: 'start',
        value: 'status',
        sortable: false,
      },
      {
        text: 'Ngày phê duyệt',
        align: 'start',
        value: 'approve_at',
        sortable: false,
      },
      {
        text: '',
        align: 'start',
        value: 'action',
        sortable: false,
      },
    ];
    const isVisible = ref(false);
    const dialogPrint = ref(false);
    const isVisibleConfirmApproval = ref(false);
    const loadingBtnUnapproval = ref(false);
    const isVisibleTemplate = ref(false);
    const isVisibleDecline = ref(false);
    const loadingBtnChangeApproval = ref(false);
    const isVisibleAddMember = ref(false);
    const loadingBtn = ref(false);
    const loadingBtnDecline = ref(false);
    const loadingBtnSign = ref(false);
    const refTabApproval = ref(1);
    const confirmContent = ref('');
    const confirmErrMsg = ref('');
    let listApprovalMember = ref([]);
    const innerContentTemplate = ref({});
    const selectedTitle = ref({});
    const setIsVisible = value => {
      isVisible.value = value;
    };
    const setLoadingBtnChangeApproval = value => {
      loadingBtnChangeApproval.value = value;
    };
    const setLoadingBtnUnapproval = value => {
      loadingBtnUnapproval.value = value;
    };
    const setConfirmErrMsg = value => {
      confirmErrMsg.value = value;
    };
    const setIsVisibleConfirmApproval = value => {
      isVisibleConfirmApproval.value = value;
    };
    const setIsVisibleAddMember = value => {
      isVisibleAddMember.value = value;
    };
    const setConfirmContent = value => {
      confirmContent.value = value;
    };
    const setLoadingBtnSign = value => {
      loadingBtnSign.value = value;
    };
    const setLoadingBtnDecline = value => {
      loadingBtnDecline.value = value;
    };
    const setLoadingBtn = value => {
      loadingBtn.value = value;
    };
    const setInnerContentTemplate = value => {
      innerContentTemplate.value = value;
    };
    const setSelectedTitle = value => {
      selectedTitle.value = value;
    };
    const setListApprovalMember = payload => {
      listApprovalMember.value = payload;
    };
    const setIsVisibleTemplate = value => {
      isVisibleTemplate.value = value;
    };
    const setIsVisibleDecline = value => {
      isVisibleDecline.value = value;
    };
    const seVisibleDialogPrint = value => {
      dialogPrint.value = value;
    };
    const {auth} = useState(['auth']);
    const content = '<p>Content of the editor.</p>';
    const token = auth.value.token;
    const editorConfig = {
      toolbar: {
        items: ['Paragraph', 'bold', 'italic', '|', 'undo', 'redo', 'numberedList', 'bulletedList', 'ImageUpload'],

        shouldNotGroupWhenFull: true,
      },
      exportPdf: {},
      pagination: {
        // A4
        pageWidth: '21cm',
        pageHeight: '29.7cm',

        pageMargins: {
          top: '20mm',
          bottom: '20mm',
          left: '12mm',
          right: '12mm',
        },
      },
      removePlugins: ['Title', 'CodeBlock', 'Code'],
      simpleUpload: {
        uploadUrl: process.env.BASE_URL + '/api/user/announcements/upload-image',
        headers: {
          Authorization: token,
        },
      },
    };
    let tab = ref(1);
    let desserts = ref([
      {
        name: 1,
      },
      {
        name: 1,
      },
    ]);
    const changeFileUpload = file => {
      console.log(file);
    };
    const removeItem = items => {
      console.log(items);
    };
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const show_extra_table = true;
    return {
      headers,
      tab,
      desserts,
      isVisible,
      loadingBtn,
      content,
      isVisibleTemplate,
      isVisibleDecline,
      isVisibleConfirmApproval,
      isVisibleAddMember,
      innerContentTemplate,
      loadingBtnDecline,
      loadingBtnSign,
      listApprovalMember,
      confirmContent,
      confirmErrMsg,
      editorConfig,
      dialogPrint,
      changeFileUpload,
      setIsVisible,
      loadingBtnChangeApproval,
      loadingBtnUnapproval,
      refTabApproval,
      selectedTitle,
      removeItem,
      setIsVisibleTemplate,
      setIsVisibleDecline,
      setInnerContentTemplate,
      setLoadingBtnSign,
      setLoadingBtn,
      setLoadingBtnDecline,
      seVisibleDialogPrint,
      setConfirmContent,
      setIsVisibleConfirmApproval,
      setConfirmErrMsg,
      setIsVisibleAddMember,
      setListApprovalMember,
      setSelectedTitle,
      setLoadingBtnChangeApproval,
      setLoadingBtnUnapproval,
      baseUrl,
      show_extra_table
    };
  },
  computed: {
    ...mapState({
      imageType: state => state.fileType.image,
      filesType: state => state.fileType.files,
      videoPath: state => state.fileType.video,
      excelPath: state => state.fileType.xls,
      pdfType: state => state.fileType.pdf,
      docType: state => state.fileType.doc,
      auth: state => state.auth,
    }),
    currentDataComputed() {
      const obj = {...this.currentData};
      if (obj.approver_titles) {
        obj.approver_titles.forEach(o => {
          let str = '';
          o.user.positions.forEach(k => {
            str += k.name + '. ';
          });

          o.roleTxt = str;
        });
      }

      return {...obj};
    },
    leftDoc() {
      if (this.currentDataComputed) {
        return [];
      }
      return this.currentDataComputed.templates ? this.currentDataComputed.templates.filter(o => !o.sign_at) : [];
    },
  },
  methods: {
    currencyStr(_str) {
      return tStr.getCurrency(_str);
    },
    getStatusApproval(value) {
      switch (value) {
        case 0:
          return 'Chưa phê duyệt';
        case 1:
          return 'Phê duyệt';
        default:
          return 'Không phê duyệt';
      }
    },
    handlerDialogCancel() {
      this.setIsVisible(false);
    },
    handlerDialogSubmit(value) {
      console.log(value);
    },
    viewPrintModel() {
      this.seVisibleDialogPrint(true);
    },
    closePrintDialog() {
      this.seVisibleDialogPrint(false);
    },
    handlerDialogTemplateCancel() {
      this.setIsVisibleTemplate(false);
    },
    handlerDialogDeclineCancel() {
      this.setIsVisibleDecline(false);
    },
    handleOpenSignTemplate(ibm) {
      this.setIsVisibleTemplate(true);
      this.setInnerContentTemplate(ibm);
    },
    clickOnDoc(ibm) {
      this.isVisible = false;
      this.setIsVisibleTemplate(true);
      this.setInnerContentTemplate(ibm);
    },
    async changeApprovalMember(val) {
      if (!val || !val.length || !this.selectedTitle || !this.selectedTitle.id) {
        return;
      }
      this.setLoadingBtnChangeApproval(true);
      const body = {
        authorized_user_id: val[0].id,
        approver_title_id: this.selectedTitle.id,
      };
      const id = this.currentDataComputed.id;
      const res = await api.approval.changeApproval(id, body);
      this.setLoadingBtnChangeApproval(false);

      if (!res) {
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        this.setConfirmErrMsg('Lỗi hệ thống, liên hệ IT để được hỗ trợ.');
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit('SET_SNACKBAR', {
            type: 'success',
            title: '',
            content: 'Chuyển việc/ủy quyền thành công',
          });
          this.refTabApproval += 1;
          await this.getCurrentData();
          this.$store.dispatch('getStatistical');
          this.handleCloseAddMember();
        } else {
          // messageErr.value =  res.data.message || res.data.data.error ;
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: res.data.message || res.data.data.error,
          });
          this.setConfirmErrMsg(res.data.message || res.data.data.error);
        }
      } catch (error) {
        //  messageErr.value = error;
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        this.setConfirmErrMsg(error);
      }
    },
    async onUnapproval() {
      this.setLoadingBtnUnapproval(true);

      const id = this.currentDataComputed.id;
      const res = await api.approval.unapproval(id);
      this.setLoadingBtnUnapproval(false);

      if (!res) {
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        // this.setConfirmErrMsg('Lỗi hệ thống, liên hệ IT để được hỗ trợ.');
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit('SET_SNACKBAR', {
            type: 'success',
            title: '',
            content: 'Thu hồi thành công',
          });
          this.refTabApproval += 1;
          await this.getCurrentData();
          this.$store.dispatch('getStatistical');
          this.handleCloseAddMember();
        } else {
          // messageErr.value =  res.data.message || res.data.data.error ;
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: res.data.message || res.data.data.error,
          });
          // this.setConfirmErrMsg(res.data.message || res.data.data.error);
        }
      } catch (error) {
        //  messageErr.value = error;
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        // this.setConfirmErrMsg(error);
      }
    },
    async toggleNoti(nextStatus) {
      this.setLoadingBtnUnapproval(true);

      const id = this.currentDataComputed.id;
      if (nextStatus) {
        const res = await api.approval.turnOnNoti(id);
        this.setLoadingBtnUnapproval(false);

        if (!res) {
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
          });
          this.setConfirmErrMsg('Lỗi hệ thống, liên hệ IT để được hỗ trợ.');
          return;
        }
        try {
          if (res.status > 199 && res.status < 399) {
            this.$store.commit('SET_SNACKBAR', {
              type: 'success',
              title: '',
              content: 'Bật thông báo thành công',
            });
            this.refTabApproval += 1;
            await this.getCurrentData();
            this.$store.dispatch('getStatistical');
          } else {
            // messageErr.value =  res.data.message || res.data.data.error ;
            this.$store.commit('SET_SNACKBAR', {
              type: 'error',
              title: '',
              content: res.data.message || res.data.data.error,
            });
          }
        } catch (error) {
          //  messageErr.value = error;
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
          });
        }
      } else {
        const res = await api.approval.turnOffNoti(id);
        this.setLoadingBtnUnapproval(false);

        if (!res) {
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
          });
          return;
        }
        try {
          if (res.status > 199 && res.status < 399) {
            this.$store.commit('SET_SNACKBAR', {
              type: 'success',
              title: '',
              content: 'Tắt thông báo thành công',
            });
            this.refTabApproval += 1;
            await this.getCurrentData();
            this.$store.dispatch('getStatistical');
          } else {
            // messageErr.value =  res.data.message || res.data.data.error ;
            this.$store.commit('SET_SNACKBAR', {
              type: 'error',
              title: '',
              content: res.data.message || res.data.data.error,
            });
          }
        } catch (error) {
          //  messageErr.value = error;
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
          });
        }
      }
    },
    handleSwitchUser(_val) {
      this.setSelectedTitle(_val);
      this.setListApprovalMember([_val.user]);
      this.setIsVisibleAddMember(true);
    },
    handleCloseAddMember() {
      this.setSelectedTitle({});
      this.setListApprovalMember([]);
      this.setIsVisibleAddMember(false);
    },
    async handlerSign() {
      this.setLoadingBtnSign(true);
      const _id = this.innerContentTemplate.id;
      const requestId = this.$route.params.id;
      const res = await api.template.sign(_id, requestId);
      this.setLoadingBtnSign(false);
      if (!res) {
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit('SET_SNACKBAR', {
            type: 'success',
            title: '',
            content: 'Ký tài liệu mẫu thành công',
          });
          await this.getCurrentData();
          this.handlerDialogTemplateCancel();
        } else {
          // messageErr.value =  res.data.message || res.data.data.error ;
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: res.data.message || res.data.data.error,
          });
        }
      } catch (error) {
        //  messageErr.value = error;
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
      }
    },
    openConfirmApproval() {
      this.setConfirmContent(
          `Bạn chắc chắn muốn <b>Phê duyệt</b> đề xuất số <b>${this.currentData.code || this.currentData.id}</b>: ${
              this.currentDataComputed.name
          } của <b>${this.currentDataComputed.creator ? this.currentDataComputed.creator.full_name : '-'}</b> không?`
      );
      this.setIsVisibleConfirmApproval(true);
    },
    handlerCancelConfirm() {
      this.setIsVisibleConfirmApproval(false);
    },
    async handleApprove() {
      this.setConfirmErrMsg('');
      if (!this.currentDataComputed) {
        return;
      }

      if (this.leftDoc.length) {
        this.isVisible = true;
        return;
      }
      this.setLoadingBtn(true);
      const id = this.currentDataComputed.id;
      const res = await api.approval.approve(id);
      this.setLoadingBtn(false);

      if (!res) {
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        this.setConfirmErrMsg('Lỗi hệ thống, liên hệ IT để được hỗ trợ.');
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit('SET_SNACKBAR', {
            type: 'success',
            title: '',
            content: 'Phê duyệt thành công',
          });
          this.refTabApproval += 1;
          await this.getCurrentData();
          this.$store.dispatch('getStatistical');
          this.handlerCancelConfirm();
        } else {
          // messageErr.value =  res.data.message || res.data.data.error ;
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: res.data.message || res.data.data.error,
          });
          this.setConfirmErrMsg(res.data.message || res.data.data.error);
        }
      } catch (error) {
        //  messageErr.value = error;
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        this.setConfirmErrMsg(error);
      }
    },
    async handleDecline(value) {
      this.setLoadingBtnDecline(true);
      const id = this.currentDataComputed.id;
      this.setLoadingBtnDecline(false);
      const res = await api.approval.decline(id, value);
      if (!res) {
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit('SET_SNACKBAR', {
            type: 'success',
            title: '',
            content: 'Từ chối thành công',
          });
          this.refTabApproval += 1;
          await this.getCurrentData();
          this.$store.dispatch('getStatistical');
        } else {
          // messageErr.value =  res.data.message || res.data.data.error ;
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: res.data.message || res.data.data.error,
          });
        }
      } catch (error) {
        //  messageErr.value = error;
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
      }
    },
    onPrint(data) {
      const currentId = this.$route.params.id;
      window.open(`/tai-lieu/in/${data.id}?c=${currentId}`);
    },
    onPrintPropose(data) {
      const currentId = this.$route.params.id;
      window.open(`/phe-duyet/in/${currentId}`);
    },
    handleCopy(item) {
      this.$router.push(`/de-xuat/sao-chep/${item.id}`);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/_variables.scss';

.container-drafts {
  .drafts-left {
    border-right: 1px solid $borderColor;
    padding: 0;
    height: calc(100vh - 190px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: transparent;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 0, 0, 0);
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #c3c5c7;
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      }
    }

    .drafts-top {
      padding-top: 8px;
      padding-bottom: 40px;
    }

    .drafts-bottom {
      padding-top: 8px;
      padding-bottom: 20px;
    }
  }

  .container-drafts-bottom {
    padding: 42px 20px 24px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $borderColor;
  }

  .trash {
    color: $txtPrimaryBlack;
    background-color: $backgroundCategory;
    height: 32px;
    font-weight: 400;
    padding-top: 7px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    border-radius: 4px;
    max-width: 100%;
  }

  .sent {
    color: $GPEblueText;
    background-color: $backgroundstronggreen;
    height: 32px;
    font-weight: 400;
    padding-top: 7px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    border-radius: 4px;
    max-width: 100%;
  }

  .approved {
    color: $GPEstronggreen;
    background-color: $backgroundlightgreen;
    height: 32px;
    font-weight: 400;
    padding-top: 7px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    border-radius: 4px;
    max-width: 100%;
  }

  .no-approve {
    color: $dangerColor;
    background-color: $backgroundlightdanger;
    height: 32px;
    font-weight: 400;
    padding-top: 7px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    border-radius: 4px;
    max-width: 100%;
  }

  .cancel {
    color: $GPEcancel;
    background-color: $backgroundcancel;
    height: 32px;
    font-weight: 400;
    padding-top: 7px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    border-radius: 4px;
    max-width: 100%;
  }
}

.style-input {
  border: 1px solid $borderColor;
  border-radius: 6px;
  height: 36px;
  padding: 0 8px;
  font-size: 14px;
}

textarea.style-input {
  height: auto !important;
}
</style>
<style lang="scss">
@import '@/assets/style/_variables.scss';

.drafts-bottom {
  .table-approver {
    table tr {
      th > * {
        font-size: 14px !important;
        color: $textColorBlur;
        font-weight: normal;
      }

      td > * {
        font-size: 14px !important;
        color: $primaryBlack;
        font-weight: normal;
      }

      th,
      td {
        height: 36px !important;
      }
    }
  }
}

.propose-ck-edittor {
  .ck-toolbar {
    border-color: $borderColor !important;
    background-color: $borderColor !important;
  }

  .ck-content {
    border-color: $borderColor !important;
    border-top: 0 !important;
  }
}

.avatar-create-user {
  object-fit: cover;
  object-position: center;
}

.btn-approve {
  box-shadow: none;
  border-radius: 8px;
  color: #006a9b !important;
  min-width: 100px;
  font-size: 14px;
  text-transform: none;

  padding: 12px 0 !important;
  height: unset !important;
}

.btn-decline {
  box-shadow: none;
  border-radius: 8px;
  color: #8194a5 !important;
  min-width: 100px;
  font-size: 14px;
  text-transform: none;
  padding: 12px 0 !important;
  height: unset !important;
}

.field-caption {
  margin-bottom: 0px !important;
}

.each-field-in-gr--detail {
  /* border: 1px solid #dee5eb; */
  /* border-radius: 6px; */
  margin-bottom: 16px;

  .field-values {
    font-size: 14px !important;
  }

  .wf-60 {
    width: 40px;
  }

  .ctm-switch {
    .v-input__slot {
      margin-bottom: 0;
    }

    .v-messages {
      display: none;
    }
  }

  .multi-opt {
    width: 100%;
    border-bottom: 1px solid #dee5eb;
  }

  .field-picker-date {
    .vue-daterange-picker {
      width: 100%;

      .date-range-slot-wrapper-tb {
        padding: 10px 6px;
        height: 40px;
        border: none !important;
        border-bottom: 1px solid $borderColor !important;
        margin-bottom: 0 !important;
      }

      .range-date-picker {
        .field-picker-date {
          justify-content: flex-start !important;
          font-size: 15px !important;

          span {
            font-size: 15px !important;
          }
        }
      }
    }
  }

  // .time-picker-m {
  //   width: 100%;
  //   border-bottom: 1px solid $borderColor;
  //   input {
  //     padding: 10px 6px;
  //     font-size: 14px;
  //     font-weight: 400;
  //     color: rgba(0, 0, 0, 0.87);
  //   }
  // }
  .date-time-label {
    width: 85px;
    font-size: 14px;
    font-weight: 500;
  }
}

.multi-checkbox {
  margin-bottom: 8px !important;

  .v-input--checkbox {
    margin-top: 0 !important;

    .v-input__control {
      .v-input__slot {
        margin-bottom: 0 !important;
      }

      .v-messages {
        display: none !important;
      }
    }
  }
}

.table-field {
  margin-right: 4px;
  border: 1px solid #dddcdc;
  border-radius: 4px;
  width: 100%;
  border-collapse: collapse;

  tr {
    th,
    td {
      border: 1px solid #dddcdc;
    }

    .input-trt {
      width: 100%;
      text-align: center;
    }

    .rough-td {
      height: 30px;
      background: #edeef07e;
    }
  }
}

.print-content {
  max-height: calc(90vh - 56px) !important;
  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.15);
    }
  }
}

.bottom-dialog-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f2f4f6;
  display: flex;

  .btn-on-cancel {
    background-color: transparent;
    color: #808890 !important;
    border-radius: 8px;
    padding: 0 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    height: 40px;
    width: 98px;
    text-align: center;
    letter-spacing: -0.0642857px;
    padding-top: 6px;
    color: $primaryText;
    cursor: pointer;
    text-transform: capitalize;
    bottom: 0;
    display: block;
    margin-left: auto;
  }

  .btn-on-yes-blue {
    padding: 0 8px;
    height: 40px;
    width: 98px;
    cursor: pointer;
    background: #006a9b;
    mix-blend-mode: normal;
    opacity: 0.9;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    padding-top: 6px;
    text-align: center;
    letter-spacing: -0.0642857px;
    color: $primaryWhite;
    text-transform: capitalize;
  }
}

.dsb-btn {
  background-color: #f6f6f6;
}

.disable-icon {
  filter: grayscale(1);
  pointer-events: none;
  cursor: progress !important;
}

.disable-icon-pr {
  filter: grayscale(1);
  /* pointer-events: none; */
  /* cursor: progress !important; */
}

.bell-icon {
  font-size: 18px !important;
}
</style>
